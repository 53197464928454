<template>
  <b-sidebar
    id="add-new-user-sidebar"
    :visible="isSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-sidebar-active', val)"
  >
    <template #default="{ hide }">
      <!-- Header -->
      <div
        class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1"
      >
        <h5 class="mb-0">
          {{ Object.entries(data).length === 0 ? $t("Add New") : $t("Update") }}
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>
      <!-- Form -->
      <validation-observer ref="formData">
        <b-form class="p-2">
          <b-row>
            <b-col
              v-if="typeLink !== 'product' && typeLink !== 'coupon' "
              cols="12"
            >
              <b-form-group
                :label="$t('Image')"
                label-for="register-image"
              >
                <!-- media -->
                <b-media no-body>
                  <b-media-aside>
                    <b-link>
                      <b-img
                        v-if="image"
                        ref="previewEl"
                        rounded
                        :src="image"
                        height="80"
                      />
                      <b-img
                        v-else
                        ref="previewEl"
                        :src="require('@/assets/images/blank/no_image.png')"
                        rounded
                        height="80"
                      />
                    </b-link>
                    <!--/ avatar -->
                  </b-media-aside>

                  <b-media-body class="mt-75 ml-75">
                    <!-- upload button -->
                    <b-button
                      v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                      variant="primary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="$refs.refInputEl.$el.click()"
                    >{{ $t("Upload") }}</b-button>
                    <b-form-file
                      ref="refInputEl"
                      accept=".webp, .jpeg, .jpg, .png, .gif"
                      :hidden="true"
                      plain
                      @change="inputImageRenderer"
                    />
                    <!--/ upload button -->

                    <!-- reset -->
                    <b-button
                      v-ripple.400="'rgba(186, 191, 199, 0.15)'"
                      variant="outline-secondary"
                      size="sm"
                      class="mb-75 mr-75"
                      @click="resetImage"
                    >{{ $t("Reset") }}</b-button>
                    <!--/ reset -->
                    <b-card-text>
                      {{ $t('Allowed') }} JPG, GIF {{ $t('or') }} PNG. {{ $t('Max size of') }}
                      300KB
                    </b-card-text>
                    <b-card-text>
                      {{ $t('Supported resolutions') }} 640×480, 800×600, 960×720, 1024×768, 1280×960, 1400×1050, 1440×1080, 1600×1200, 1856×1392, 1920×1440,
                    </b-card-text>
                  </b-media-body>
                </b-media>
                <!--/ media -->
              </b-form-group>
            </b-col>
            <b-col
              v-if="typeLink === 'product'"
              cols="12"
            >
              <b-form-group
                :label="$t('Product')"
                label-for="register-name"
              >
                <v-select
                  v-model="productId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionProduct"
                  :reduce="(OptionProduct) => OptionProduct._id"
                  :clearable="false"
                  label="name"
                  @search="onSearchProduct"
                />
              </b-form-group>
            </b-col>
            <b-col
              v-if="typeLink === 'coupon'"
              cols="12"
            >
              <b-form-group
                :label="$t('Coupon')"
                label-for="register-name"
              >
                <v-select
                  v-model="couponId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionCoupon"
                  :reduce="(OptionCoupon) => OptionCoupon._id"
                  :clearable="false"
                  label="name"
                  @search="onSearchCoupon"
                >
                  <div
                    slot="list-footer"
                    class="m-1"
                  >
                    <b-row>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          block
                          :disabled="currentPageCoupon <= 1 ? true : false"
                          @click="nextPageCoupon(currentPageCoupon - 1)"
                        >
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          block
                          disabled
                        >{{ currentPageCoupon }}</b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          block
                          :disabled="
                            currentPageCoupon * perPageCoupon >=
                              queriedItemsCoupon
                              ? true
                              : false
                          "
                          @click="nextPageUser(currentPageCoupon + 1)"
                        >
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              v-if="typeLink === 'couponCategory'"
              cols="12"
            >
              <b-form-group
                :label="$t('Coupon Category')"
                label-for="register-name"
              >
                <v-select
                  v-model="couponCategoryId"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionCouponCategory"
                  :reduce="(OptionCouponCategory) => OptionCouponCategory._id"
                  :clearable="false"
                  label="name"
                  @search="onSearchCouponCategory"
                >
                  <div
                    slot="list-footer"
                    class="m-1"
                  >
                    <b-row>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          block
                          :disabled="currentPageCouponCategory<= 1 ? true : false"
                          @click="nextPageCouponCategory(currentPageCouponCategory - 1)"
                        >
                          <feather-icon
                            icon="ChevronLeftIcon"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="primary"
                          block
                          disabled
                        >{{ currentPageCouponCategory }}</b-button>
                      </b-col>
                      <b-col cols="4">
                        <b-button
                          v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                          variant="info"
                          block
                          :disabled="
                            currentPageCouponCategory * perPageCouponCategory >=
                              queriedItemsCouponCategory
                              ? true
                              : false
                          "
                          @click="nextPageUser(currentPageCouponCategory + 1)"
                        >
                          <feather-icon
                            icon="ChevronRightIcon"
                            size="18"
                          />
                        </b-button>
                      </b-col>
                    </b-row>
                  </div>
                </v-select>
              </b-form-group>
            </b-col>
            <b-col
              cols="12"
            >
              <b-form-group
                :label="$t('Tag Menu')"
                label-for="register-tagMenu"
              >
                <v-select
                  v-model="tagMenu"
                  label="name"
                  :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                  :options="OptionTagMenu"
                  :reduce="OptionTagMenu => OptionTagMenu.value"
                  :clearable="true"
                  class="select-size"
                />
              </b-form-group>

              <template v-if="tagMenu === 'PRODUCT_CATEGORY'">
                <b-form-group
                  :label="$t('Product Category')"
                  label-for="register-name"
                >
                  <v-select
                    v-model="productCategoryId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionProductCategory"
                    :reduce="(OptionProductCategory) => OptionProductCategory._id"
                    :clearable="false"
                    label="name"
                    @search="onSearchProductCategory"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageProductCategory<= 1 ? true : false"
                            @click="nextPageProductCategory(currentPageProductCategory - 1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >{{ currentPageProductCategory }}</b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="
                              currentPageProductCategory * perPageProductCategory >=
                                queriedItemsProductCategory
                                ? true
                                : false
                            "
                            @click="nextPageUser(currentPageProductCategory + 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </v-select>
                </b-form-group>
              </template>
              <template v-if="tagMenu === 'QUESTIONNAIRE'">
                <b-form-group
                  :label="$t('Questionnaire')"
                  label-for="register-name"
                >
                  <v-select
                    v-model="questionnaireId"
                    :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'"
                    :options="OptionQuestionnaire"
                    :reduce="(OptionQuestionnaire) => OptionQuestionnaire._id"
                    :clearable="false"
                    label="name"
                    @search="onSearchQuestionnaire"
                  >
                    <div
                      slot="list-footer"
                      class="m-1"
                    >
                      <b-row>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="currentPageQuestionnaire<= 1 ? true : false"
                            @click="nextPageQuestionnaire(currentPageQuestionnaire - 1)"
                          >
                            <feather-icon
                              icon="ChevronLeftIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="primary"
                            block
                            disabled
                          >{{ currentPageQuestionnaire }}</b-button>
                        </b-col>
                        <b-col cols="4">
                          <b-button
                            v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                            variant="info"
                            block
                            :disabled="
                              currentPageQuestionnaire * perPageQuestionnaire >=
                                queriedItemsQuestionnaire
                                ? true
                                : false
                            "
                            @click="nextPageQuestionnaire(currentPageQuestionnaire+ 1)"
                          >
                            <feather-icon
                              icon="ChevronRightIcon"
                              size="18"
                            />
                          </b-button>
                        </b-col>
                      </b-row>
                    </div>
                  </v-select>
                </b-form-group>
              </template>
            </b-col>
            <b-col
              v-if="typeLink === 'link'"
              cols="12"
            >
              <!-- firstName -->
              <b-form-group
                :label="$t('Link')"
                label-for="register-link-url"
              >
                <validation-provider
                  #default="{ errors }"
                  name="Link URL"
                  vid="link-url"
                  rules
                >
                  <b-form-input
                    id="register-link-url"
                    v-model="link"
                    name="register-link-url"
                    :state="errors.length > 0 ? false : null"
                    :placeholder="$t('Link')"
                  />
                  <small class="text-danger">{{ errors[0] }}</small>
                </validation-provider>
              </b-form-group>
            </b-col>
            <b-col cols="12">
              <b-form-group
                :label="$t('Name')"
                label-for="register-name"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_name`"
                    cols="12"
                  >
                    <!-- firstName -->
                    <!-- firstName -->
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="name[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="name[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'name')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>

            <b-col cols="12">
              <b-form-group
                :label="$t('Description')"
                label-for="description"
              >
                <b-row>
                  <b-col
                    v-for="(item, index) in language"
                    :key="`${index}_description`"
                    cols="12"
                  >
                    <b-form-group
                      :label="$t(item.name)"
                      :label-for="item.name"
                    >
                      <validation-provider
                        #default="{ errors }"
                        :name="item.name"
                        :vid="item.name"
                      >
                        <b-form-input
                          v-if="description[index]"
                          :id="item.name"
                          :name="item.name"
                          :value="description[index]['value']"
                          :state="errors.length > 0 ? false : null"
                          :placeholder="$t(item.name)"
                          @input="inputLanguage($event, item.lang, index, 'description')"
                        />
                        <small class="text-danger">{{ errors[0] }}</small>
                      </validation-provider>
                    </b-form-group>
                  </b-col>
                </b-row>
              </b-form-group>
            </b-col>

          </b-row>
          <div class="d-flex mt-2">
            <b-button
              variant="primary"
              class="mr-2"
              @click="validationForm()"
            >
              {{ $t('Submit') }}
            </b-button>
            <b-button
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              {{ $t('Cancel') }}
            </b-button>
          </div>
        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import {
  BSidebar,
  BButton,
  BFormFile,
  BMedia,
  BMediaAside,
  BMediaBody,
  BLink,
  BImg,
  BCardText,
  BInputGroupAppend,
  BInputGroup,
  BFormInput,
  BForm,
  BFormGroup,
  BRow,
  BCol,
  BFormCheckbox,
} from 'bootstrap-vue'
import { required } from '@validations'
import { togglePasswordVisibility } from '@core/mixins/ui/forms'
import Ripple from 'vue-ripple-directive'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import flatPickr from 'vue-flatpickr-component'
import vSelect from 'vue-select'
import store from '@/store'
import Cleave from 'vue-cleave-component'

export default {
  components: {
    BSidebar,
    BButton,
    BFormFile,
    BMedia,
    BMediaAside,
    BMediaBody,
    BLink,
    BImg,
    BCardText,
    BForm,
    // eslint-disable-next-line vue/no-unused-components
    BFormInput,
    flatPickr,
    BFormGroup,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroupAppend,
    // eslint-disable-next-line vue/no-unused-components
    BInputGroup,
    ValidationProvider,
    ValidationObserver,
    BRow,
    BCol,
    BFormCheckbox,
    // eslint-disable-next-line vue/no-unused-components
    vSelect,
    Cleave,
  },
  directives: {
    Ripple,
  },
  mixins: [togglePasswordVisibility],
  model: {
    prop: 'isSidebarActive',
    event: 'update:is-sidebar-active',
  },
  props: {
    storeModuleName: {
      type: String,
      required: true,
    },
    storeModuleNameProduct: {
      type: String,
      required: true,
    },
    storeModuleNameCoupon: {
      type: String,
      required: true,
    },
    storeModuleNameCouponCategory: {
      type: String,
      required: true,
    },
    storeModuleNameProductCategory: {
      type: String,
      required: true,
    },
    storeModuleNameQuestionnaire: {
      type: String,
      required: true,
    },
    isSidebarActive: {
      type: Boolean,
      required: true,
    },
    data: {
      type: Object,
      default: () => {},
    },
    show: {
      type: Boolean,
      required: false,
    },
  },
  data() {
    return {
      optionCleave: {
        number: {
          numeral: true,
        },
      },
      dataId: null,
      image: '',
      name: [],
      description: [],
      productId: null,
      couponId: null,
      couponCategoryId: null,
      productCategoryId: null,
      questionnaireId: null,
      tagMenu: '',
      link: '',
      currentPageProduct: 0,
      searchQueryProduct: '',
      perPageProduct: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageCoupon: 0,
      searchQueryCoupon: '',
      perPageCoupon: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageCouponCategory: 0,
      searchQueryCouponCategory: '',
      perPageCouponCategory: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageProductCategory: 0,
      searchQueryProductCategory: '',
      perPageProductCategory: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      currentPageQuestionnaire: 0,
      searchQueryQuestionnaire: '',
      perPageQuestionnaire: localStorage.getItem('itemsPerPage')
        ? localStorage.getItem('itemsPerPage')
        : 30,
      required,
    }
  },
  computed: {
    OptionTagMenu() {
      return [
        { name: this.$t('Product Category'), value: 'PRODUCT_CATEGORY' },
        { name: this.$t('Live broadcast'), value: 'LIVE_BROADCAST' },
        { name: this.$t('Radio online'), value: 'RADIO_ONLINE' },
        { name: this.$t('Products'), value: 'PRODUCTS' },
        { name: this.$t('Questionnaire'), value: 'QUESTIONNAIRE' },
      ]
    },
    typeLink() {
      return store.state[this.storeModuleName].respData != null
        ? store.state[this.storeModuleName].respData.typeLink
        : ''
    },
    OptionLink() {
      return [
        { name: this.$t('Product'), value: 'product' },
        { name: this.$t('Coupon'), value: 'coupon' },
        { name: this.$t('Coupon Category'), value: 'couponCategory' },
        { name: this.$t('Link'), value: 'link' },
      ]
    },
    language() {
      return store.state.appConfig.systemConfig
        ? store.state.appConfig.systemConfig.language
        : []
    },
    OptionProduct() {
      return store.state[this.storeModuleNameProduct].respData != null
        ? store.state[this.storeModuleNameProduct].respData.data
        : []
    },
    queriedItemsProduct() {
      return store.state[this.storeModuleNameProduct].respData != null
        ? store.state[this.storeModuleNameProduct].respData.max
        : 0
    },
    OptionCoupon() {
      return store.state[this.storeModuleNameCoupon].respData != null
        ? store.state[this.storeModuleNameCoupon].respData.data.map(e => {
          const indexLang = e.name.findIndex(
            name => name.lang === this.$i18n.locale,
          )
          if (indexLang > -1) {
            return {
              _id: e._id,
              name: e.name[indexLang].value,
            }
          }
          return {
            _id: e._id,
            name: e.name[0].value,
          }
        })
        : []
    },
    queriedItemsCoupon() {
      return store.state[this.storeModuleNameCoupon].respData != null
        ? store.state[this.storeModuleNameCoupon].respData.max
        : 0
    },
    OptionCouponCategory() {
      return store.state[this.storeModuleNameCouponCategory].respData != null
        ? store.state[this.storeModuleNameCouponCategory].respData.data.map(
          e => {
            const indexLang = e.name.findIndex(
              name => name.lang === this.$i18n.locale,
            )
            if (indexLang > -1) {
              return {
                _id: e._id,
                name: e.name[indexLang].value,
              }
            }
            return {
              _id: e._id,
              name: e.name[0].value,
            }
          },
        )
        : []
    },
    OptionProductCategory() {
      return store.state[this.storeModuleNameProductCategory].respData != null
        ? store.state[this.storeModuleNameProductCategory].respData.data.map(
          e => {
            const indexLang = e.name.findIndex(
              name => name.lang === this.$i18n.locale,
            )
            if (indexLang > -1) {
              return {
                _id: e._id,
                name: e.name[indexLang].value,
              }
            }
            return {
              _id: e._id,
              name: e.name[0].value,
            }
          },
        )
        : []
    },
    queriedItemsCouponCategory() {
      return store.state[this.storeModuleNameCouponCategory].respData != null
        ? store.state[this.storeModuleNameCouponCategory].respData.max
        : 0
    },
    queriedItemsProductCategory() {
      return store.state[this.storeModuleNameProductCategory].respData != null
        ? store.state[this.storeModuleNameProductCategory].respData.max
        : 0
    },
    OptionQuestionnaire() {
      return store.state[this.storeModuleNameQuestionnaire].respData != null
        ? store.state[this.storeModuleNameQuestionnaire].respData.data.map(
          e => ({
            _id: e._id,
            name: e.name,
          }),
        )
        : []
    },
    queriedItemsQuestionnaire() {
      return store.state[this.storeModuleNameQuestionnaire].respData != null
        ? store.state[this.storeModuleNameQuestionnaire].respData.max
        : 0
    },
  },
  watch: {
    isSidebarActive(val) {
      if (val) {
        if (Object.entries(this.data).length === 0) {
          this.$refs.formData.reset()
          this.initValues()
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )
              console.log(indexLanguage)

              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang,
              )
              console.log(indexLanguage)

              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
          }
        } else {
          const {
            _id,
            image,
            name,
            description,
            productId,
            couponId,
            couponCategoryId,
            productCategoryId,
            questionnaireId,
            link,
            tagMenu,
          } = this.data
          this.dataId = _id
          this.image = image
          if (productId) {
            this.productId = productId._id
          }
          if (couponId) {
            this.couponId = couponId._id
          }
          if (couponCategoryId) {
            this.couponCategoryId = couponCategoryId._id
          }
          if (productCategoryId) {
            this.productCategoryId = productCategoryId._id
          }
          if (questionnaireId) {
            this.questionnaireId = questionnaireId
          }
          this.link = link
          if (tagMenu) {
            this.tagMenu = tagMenu
          }
          this.name = name
          if (this.name.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.name.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.name.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.name[index].lang,
              )
              if (indexLanguage < 0) {
                this.name.splice(index, 1)
              }
            }
          }
          this.description = description
          if (this.description.length !== this.language.length) {
            for (let index = 0; index < this.language.length; index += 1) {
              const indexLanguage = this.description.findIndex(
                element => element.lang === this.language[index].lang,
              )
              if (indexLanguage < 0) {
                this.description.push({
                  lang: this.language[index].lang, // th , en
                  value: '',
                })
              }
            }
            for (let index = 0; index < this.description.length; index += 1) {
              const indexLanguage = this.language.findIndex(
                element => element.lang === this.description[index].lang,
              )
              if (indexLanguage < 0) {
                this.description.splice(index, 1)
              }
            }
          }
        }
      }
    },
    typeLink(val) {
      if (val) {
        if (val === 'product') {
          this.getProduct()
        } else if (val === 'coupon') {
          this.getCoupon()
        } else if (val === 'couponCategory') {
          this.getCouponCategory()
        } else if (val === 'link') {
        } else if (val === 'html') {
        } else if (val === 'actionMenu') {
          if (this.tagMenu === 'PRODUCT_CATEGORY') {
            this.getProductCategory()
          }
        }
      }
    },
    tagMenu(val) {
      if (val) {
        if (val === 'PRODUCT_CATEGORY') {
          this.currentPageProductCategory = 0

          this.getProductCategory()
        }
        if (val === 'QUESTIONNAIRE') {
          this.currentPageQuestionnaire = 0

          this.getQuestionnaire()
        }
      }
    },
  },
  methods: {
    nextPageProduct(page) {
      this.currentPageProduct = page
      this.getProduct()
    },
    onSearchProduct(query) {
      this.searchQueryProduct = query
      this.currentPageProduct = 0
      this.getProduct()
    },
    nextPageCoupon(page) {
      this.currentPageCoupon = page
      this.getCoupon()
    },
    onSearchCoupon(query) {
      this.searchQueryCoupon = query
      this.currentPageCoupon = 0
      this.getCoupon()
    },
    onSearchCouponCategory(query) {
      this.searchQueryCouponCategory = query
      this.currentPageCouponCategory = 0
      this.getCouponCategory()
    },
    nextPageProductCategory(page) {
      this.currentPageProductCategory = page
      this.getProductCategory()
    },
    onSearchProductCategory(query) {
      this.searchQueryProductCategory = query
      this.currentPageProductCategory = 0
      this.getProductCategory()
    },
    nextPageQuestionnaire(page) {
      this.currentPageQuestionnaire = page
      this.getQuestionnaire()
    },
    onSearchQuestionnaire(query) {
      this.searchQueryQuestionnaire = query
      this.currentPageQuestionnaire = 0
      this.getQuestionnaire()
    },
    getProduct() {
      const obj = {
        currentPage: this.currentPageProduct,
        pageSize: this.perPageProduct,
        searchQuery: this.searchQueryProduct,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameProduct}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    getCoupon() {
      const obj = {
        currentPage: this.currentPageCoupon,
        pageSize: this.perPageCoupon,
        searchQuery: this.searchQueryCoupon,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameCoupon}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    getCouponCategory() {
      const obj = {
        currentPage: this.currentPageCouponCategory,
        pageSize: this.perPageCouponCategory,
        searchQuery: this.searchQueryCouponCategory,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameCouponCategory}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    getProductCategory() {
      const obj = {
        currentPage: this.currentPageProductCategory,
        pageSize: this.perPageProductCategory,
        searchQuery: this.searchQueryProductCategory,
        position: 0,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameProductCategory}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    getQuestionnaire() {
      const obj = {
        currentPage: this.currentPageQuestionnaire,
        pageSize: this.perPageQuestionnaire,
        searchQuery: this.searchQueryQuestionnaire,
        position: 0,
        categories: [],
      }
      store
        .dispatch(`${this.storeModuleNameQuestionnaire}/get`, obj)
        .then(result => {
          // this.showToast('success', 'bottom-right')
        })
        .catch(error => {
          console.log('fetchUsers Error : ', error)
          this.$toast({
            component: ToastificationContent,
            position: 'bottom-right',
            props: {
              title: this.$t('Error'),
              icon: 'ErrorIcon',
              variant: 'danger',
              text: this.$t(error.response.data.message),
            },
          })
          // this.showToast('danger', 'bottom-right')
        })
    },
    inputLanguage(input, lang, index, type) {
      if (type === 'name') {
        if (this.name[index].lang === lang) {
          this.name[index].value = input
        }
      } else if (type === 'description') {
        if (this.description[index].lang === lang) {
          this.description[index].value = input
        }
      }
    },
    inputImageRenderer(input) {
      // eslint-disable-next-line prefer-destructuring
      if (input.target.files && input.target.files[0]) {
        // if (input.target.files[0].size > 300000) {
        //   this.$toast({
        //     component: ToastificationContent,
        //     position: 'bottom-right',
        //     props: {
        //       title: this.$t('Error'),
        //       icon: 'ErrorIcon',
        //       variant: 'danger',
        //       text: `${this.$t('Allowed')} JPG, GIF ${this.$t(
        //         'or',
        //       )} PNG. ${this.$t('Max size of')} 300KB`,
        //     },
        //   })
        //   return
        // }

        const reader = new FileReader()
        reader.onload = e => {
          this.image = e.target.result
          // const obj = { image: e.target.result }
          // this.$store
          //   .dispatch(`${this.storeModuleName}/uploadImage`, obj)
          //   .then(resp => {
          //     console.log(resp)
          //     this.image = resp.data.data.url
          //   })
          //   .catch(err => {
          //     console.error(err)
          //   })
        }
        reader.readAsDataURL(input.target.files[0])
      }
    },
    resetImage() {
      this.image = ''
    },
    initValues() {
      this.dataId = null
      this.image = ''
      this.name = []
      this.description = []
      this.productId = null
      this.couponId = null
      this.couponCategoryId = null
      this.productCategoryId = null
      this.questionnaireId = null
      this.link = ''
    },
    validationForm() {
      this.$refs.formData.validate().then(success => {
        if (success) {
          const obj = {
            id: this.dataId,
            promoteId: this.$route.params.id,
            image: this.image,
            name: this.name,
            description: this.description,
            productId: this.productId,
            couponId: this.couponId,
            couponCategoryId: this.couponCategoryId,
            productCategoryId: this.productCategoryId,
            questionnaireId: this.questionnaireId,
            tagMenu: this.tagMenu,
            link: this.link,
          }
          this.$emit('update:show', true)
          if (this.dataId !== null) {
            store
              .dispatch(`${this.storeModuleName}/update`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          } else {
            delete obj.id
            store
              .dispatch(`${this.storeModuleName}/add`, obj)
              .then(result => {
                this.$emit('update:is-sidebar-active', false)
                this.$emit('update:show', false)

                // this.showToast('success', 'bottom-right')
              })
              .catch(error => {
                console.log('fetchUsers Error : ', error)
                this.$toast({
                  component: ToastificationContent,
                  position: 'bottom-right',
                  props: {
                    title: this.$t('Error'),
                    icon: 'ErrorIcon',
                    variant: 'danger',
                    text: this.$t(error.response.data.message),
                  },
                })
                this.$emit('update:show', false)
                // this.showToast('danger', 'bottom-right')
              })
          }
        }
      })
    },
  },
}
</script>

<style lang="scss">
@import "@core/scss/vue/libs/vue-select.scss";
@import "@core/scss/vue/libs/vue-flatpicker.scss";

#add-new-user-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
